
import Vue from 'vue'
import { Line } from 'vue-chartjs'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'lineChart',
    extends: Line,
    props: {chartdata: {type:Object}},
    mounted(this:any){
        if(!this.isTesting)
            this.renderChart(this.chartdata.data,this.chartdata.options)
    },
    watch: {
        //TODO: Save instead of rendering new data if not visible, render when visible switched
        chartdata: {
            handler(this:any,n){ 
                if(!this.isTesting)
                    this.renderChart(n.data,n.options) 
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters(['isTesting'])
    }
})
